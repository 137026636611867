import React from "react"
import { Header } from "../header"
import { Footer } from "../home/footer"
import { Hireteam } from "../development/hireteam"
import { clouddevtech, processSteps, services, technologies, whyReproSoftData } from "../../techutils.jsx/clouddevopsutils"

import { GlobalPresence } from "./globalpresence"
import { Hiring } from "./hirings"
import { Solutions } from "./solutions"
import { ContactHire } from "./contactushiring"

export const CloudDevopsPage =()=> {
   
    return(
        <React.Fragment>
            <Header />
          
  <div className="dot-net-development-wrapper ">
  <section className="img-background" style={{backgroundImage: `url(${clouddevtech.bgimg})`}}>
    <div className="container text-white">
    <h1>{clouddevtech.header}</h1>
    <p>{clouddevtech.content}</p>
    </div>
  </section>
  <section className="repro-service-section ">
  <div className="container">
    <div className="inner-decription mb-4">
      <p>
       {clouddevtech.abouttech}
      </p>
      <p>
       {clouddevtech.abouttech2}
      </p>
    </div>
    
    <div className="title-block text-center mb-5">
      <h2 className="mb-3">{clouddevtech.servicesheader}</h2>
      <p>
      {clouddevtech.servicecontent}
      </p>
    </div>
  </div>
  <div className="container">
    <div className="row">
      {technologies.map((item, index) => (
        <div
          key={index}
          className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4"
        >
          <div className="card h-100 text-center border">
            <div className="card-body">
              <img
                src={item.img}
                alt={item.title}
                className="mb-3"
                style={{ width: "60px", height: "auto" }}
              />
              <h3 className="card-title h5">{item.title}</h3>
              <p className="card-text">{item.description}</p>
             
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>
<ContactHire text={'a Cloud&DevOps'}  />

<section className="pb-0">
  <div class="container">
    <div class="text-center mb-5">
      <h2>Our Cloud Expertise</h2>
      <p>We provide the best Cloud and DevOps Services to business leaders and help them to improve agility and drive efficiency</p>
    </div>
    <div class="row" style={{alignItems:'start'}}>
      <div class="col-12 col-md-4 mb-4">
        <div style={{maxWidth:'380px'}}>
          <h3>Amazon AWS</h3>
          <p>RiseProSoft Solutionss aims to provide expertise in Amazon Web Services to customize and deliver dependable, robust, and cost-effective cloud-based solutions. Our expertise in AWS enables us to build applications according to various business needs regardless of the sector, type, or size of your company.</p>
          <ul className="inner-page-listing checklogo">
            <li>AWS Consultation and Development</li>
            <li>Customized Cloud Native Application on AWS</li>
            <li>DevOps and Infrastructure Automation</li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4">
        <div style={{maxWidth:'380px'}}>
          <h3>Microsoft Azure</h3>
          <p>We help you design, maintain, and deploy powerful apps on the Azure platform at a higher speed by leveraging our in-depth industry experience and elevated cloud computing abilities. Our top priority is to provide businesses with the most effective cloud solutions imaginable.</p>
          <ul className="inner-page-listing checklogo">
            <li>Microsoft Azure setup, configuration, and migration</li>
            <li>Bespoke Azure Development</li>
            <li>Azure DevOps and Infrastructure Automation</li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4">
        <div style={{maxWidth:'380px'}}>
          <h3>Google Cloud</h3>
          <p>We can assist you with automating your cloud resources and delivering the cloud solution that best meets your key business requirements. Our cloud professionals provide you with the finest cloud solutions on the Google cloud platform in order to alter your company's worth.</p>
          <ul className="inner-page-listing checklogo">
            <li>GCP Approach and Consulting</li>
            <li>Migration to Google Cloud Platform</li>
            <li>Integrated GCP Management</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section >
      <div className="container">
        <div className="title-block mb-5 text-center">
          <h2>Our App Development Process</h2>
          <p>We ensure delivering consistent responses at every step of the app development lifecycle.</p>
        </div>
        <div className="process-wrapper">
          {processSteps.map((step, index) => (
            <div className="process-outer" key={index}>
              <div className="process-inner">
                <div className={step.class}>
                  <span>
                    <em>{step.step}</em>
                  </span>
                  <h3>{step.title}</h3>
                </div>
                <p>{step.description}</p>
                <ul className="risepro-bullet-list bulletpoints-grid" style={{paddingLeft:'75px'}}>
                 {step.bulletPoint?.map((feature, index) => (
                    <li key={index}>{feature}</li>
                 ))}
              </ul>
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </section>


<section className="repro-app-dev-experience mb-5" style={{ backgroundImage: `url(${clouddevtech.techexperiencebg})`,backgroundRepeat:'no-repeat' }}>
 
  <div className="container position-relative">
    <div className="text-center mb-5">
      <h2 className="text-white">{clouddevtech.techexperience}</h2>
      <p className="text-white">
        {clouddevtech.techexperiencecontent}
      </p>
    </div>
    <div className="repro-experience-wrapper bg-light p-4 rounded shadow-sm">
      <h3 className="mb-4 text-center">{clouddevtech.advancestech}</h3>
      <div className="experience-content-wrapper">
        <p>
          {clouddevtech.advancestechcontent}
        </p>
        <div class="experience-content-wrapper">
				
				<ul className="inner-page-listing two-column">
            {services.map((service, index) => (
                <li key={index}>
                    <p>{service}</p>
                </li>
            ))}
        </ul>
			</div>
      </div>
    </div>
  </div>
</section>

<Solutions/>
<section className="repro-why-section py-5">
  <div className="container">
    <h2 className="text-center mb-4">Why reproSoft?</h2>
    <div className="row justify-content-center">
      {whyReproSoftData.map((item, index) => (
        <div key={index} className="col-md-6 col-lg-3 mb-4">
          <div className="card h-100 text-center">
            <div className="card-body">
              <span className="d-inline-block mb-3">
                <img
                  alt={item.altText}
                  className="img-fluid"
                  width={60}
                  height={60}
                  src={item.imgSrc}
                />
              </span>
              <h5 className="card-title">{item.title}</h5>
              <p className="card-text">{item.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>


   <Hiring/>
   <Hireteam title={'Sharepoint Developers'}/>
    <GlobalPresence />
  </div>

<Footer/>

        </React.Fragment>
    )
}
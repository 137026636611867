import React from "react";


export const Aboutus = () => {
  
  return (
    <React.Fragment>

      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 aboutus"></div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <h1 className='pt-5'>About Us</h1>
            <p className='pt-5'>
            At RiseProSoft Solutions, we are your trusted partner in IT Development, Software Solutions, innovative Mobile App Development, and cutting-edge Web Design services. Specializing in crafting tailored solutions, our expertise spans across areas such as Search Engine Optimization (SEO), on-page optimization, search-friendly web development, social networking strategies, and link building. Our commitment to innovation and adaptability sets us apart, enabling us to consistently meet the evolving demands of the industry. From supporting small start-ups to empowering large enterprises, we’ve built a track record of driving measurable success for our clients.
            At RiseProSoft Solutions, we don’t just deliver services; we forge long-lasting partnerships to help businesses thrive in the digital era.
            </p>
          </div>
        </div>
      </div>

</React.Fragment>
);
};

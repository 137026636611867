import React, { useState } from "react";
import { Header } from "./header";
import { Footer } from "./home/footer";

export const Contactus = () => {
  const [formValues, setFormValues] = useState({
    name_contact: "",
    email_contact: "",
    PhoneNumber: "",
    company_name: "",
    DescriptionContact: "",
    file_attach_contact: null,
  });
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormValues({
      ...formValues,
      [name]: files ? files[0] : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.name_contact) formErrors.name_contact = "Name is required";
    if (!formValues.email_contact) {
      formErrors.email_contact = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email_contact)) {
      formErrors.email_contact = "Invalid email address";
    }
    if (!formValues.PhoneNumber)
      formErrors.PhoneNumber = "Phone number is required";
    if (!formValues.DescriptionContact)
      formErrors.DescriptionContact = "Description is required";
    if (formValues.DescriptionContact.length > 500)
      formErrors.DescriptionContact =
        "Description can't be longer than 500 characters";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      Name: formValues.name_contact,
      email_contact: formValues.email_contact,
      PhoneNumber: formValues.PhoneNumber,
      company_name: formValues.company_name,
      DescriptionContact: formValues.DescriptionContact,
    };

    if (validateForm()) {
      try {
        await fetch("https://getform.io/f/aejjokpb", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        // Show popup on successful submission
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false); // Hide popup after 3 seconds
          setFormValues({
            name_contact: "",
            email_contact: "",
            PhoneNumber: "",
            company_name: "",
            DescriptionContact: "",
            file_attach_contact: null,
          });
        }, 3000);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Header />
      <section className="contact-section">
        <div className="container">
          <div
            className="contact-banner-wrapper row"
            style={{ alignItems: "start" }}
          >
            <div className="left-col inner-col col-md-6">
              <div className="left-col-wrapper">
                <div className="heading-block">
                  <h1 className="text-white">Contact us</h1>
                  <p className="text-white">
                    Please send us your requirements and we'll get back to you
                    at the earliest.
                  </p>
                </div>
                <div className="content-block">
                  <ul className="inquiry-listing">
                    <li className="text-white">
                      <span>Inquiry: </span>
                      <a
                        href="mailto:info@riseprosoftsolutions.com"
                        title="Mail Us"
                      >
                        info@riseprosoftsolutions.com
                      </a>
                    </li>
                    <li className="text-white">
                      <span>Work with us: </span>
                      <a
                        href="mailto:career@riseprosoftsolutions.com"
                        title="Mail Us"
                      >
                        career@riseprosoftsolutions.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-col inner-col col-md-6">
              <div className="contact-form-wrapper">
                <div className="form-wrapper">
                  <div className="title-wrapper">
                    <h2>Get in touch</h2>
                  </div>
                  <form
                    autoComplete="off"
                    id="ContactForm"
                    className="contact-form"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-group name-div">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name_contact"
                        id="name_contact"
                        className="form-control your-name"
                        placeholder="Name"
                        value={formValues.name_contact}
                        onChange={handleChange}
                      />
                      {errors.name_contact && (
                        <div className="error">{errors.name_contact}</div>
                      )}
                    </div>
                    <div className="form-group email-div">
                      <label htmlFor="mail">Email Address</label>
                      <input
                        type="email"
                        name="email_contact"
                        id="mail"
                        className="form-control your-email"
                        placeholder="Email Address"
                        value={formValues.email_contact}
                        onChange={handleChange}
                      />
                      {errors.email_contact && (
                        <div className="error">{errors.email_contact}</div>
                      )}
                    </div>
                    <div className="form-group number-div">
                      <label htmlFor="phnumber_contact">Phone Number</label>
                      <input
                        type="text"
                        name="PhoneNumber"
                        id="phnumber_contact"
                        className="form-control your-number"
                        placeholder="Phone Number"
                        value={formValues.PhoneNumber}
                        onChange={handleChange}
                      />
                      {errors.PhoneNumber && (
                        <div className="error">{errors.PhoneNumber}</div>
                      )}
                    </div>
                    <div className="form-group desc-div">
                      <label htmlFor="description">Message</label>
                      <textarea
                        id="description"
                        className="form-control"
                        maxLength={500}
                        name="DescriptionContact"
                        placeholder="Description"
                        value={formValues.DescriptionContact}
                        onChange={handleChange}
                      />
                      {errors.DescriptionContact && (
                        <div className="error">{errors.DescriptionContact}</div>
                      )}
                    </div>
                    <div className="btn-wrapper mt-4">
                      <div className="submit-btn-wrapper">
                        <button type="submit" className="btn w-100" id="submit">
                          Submit
                        </button>
                        {/* Popup message */}
                        {showPopup && (
                          <div
                            style={{
                              marginTop: '10px', // Space below the button
                              color: 'green',
                              background: '#f0f0f0',
                              padding: '5px',
                              borderRadius: '4px',
                              border: '1px solid #dcdcdc',
                            }}
                          >
                            Submitted Successfully.
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </>
  );
};
